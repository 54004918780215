<template>
  <div class="login">
    <!-- <div class="logo">
      <img class="logoimg" src="../assets/logo.png" alt="">
    </div> -->
    <!-- <div class="gohome">
      <el-button type="primary" size="large" @click="goindex">进入首页</el-button>
    </div> -->
    <div class="fromcont">
     
      <div class="loginfrom" v-if="sjh">
        <div class="denglu">
          注册
          <div class="qiehuan">
            <a href="javascript:;" @click="sjh =false">
              使用账号登陆
            </a>
          </div>
        </div>
        <el-form :model="form" label-position="right" label-width="10px" size="large">
          <el-form-item>
            <!-- <el-input class="input" v-model="form.name"  placeholder="请输入账号" /> -->
            <el-input class="input" v-model="form.newname"  placeholder="请输入账号">
             
            </el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-input class="input" v-model="form.name"  placeholder="请输入账号" /> -->
            <el-input class="input" v-model="form.newpass" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="pass">
            <el-input class="input" v-model="form.pass" type="password" autocomplete="off" placeholder="请输入密码" />
          </el-form-item> -->
          <el-form-item>
            <el-button class="loginbutton input" type="primary" @click="onregistered">登录</el-button>
          </el-form-item>
        </el-form>
       

        <!-- 手机号登录 -->
        <!-- <el-form :model="form" label-position="right" label-width="10px" size="large">
          <el-form-item>
            <el-input class="input" v-model="form.sjh"  placeholder="请输入手机号">
              <template #prepend>
                +86
              </template>

            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input class="input" v-model="form.yzm"  placeholder="请输入短信验证码">
              <template #prepend>
                <el-button :icon="Search" />
              </template>
              <template #append>
                <el-button type="primary" disabled>获取验证码</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="loginbutton input" type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form> -->
      </div>
      <div class="loginfrom" v-else>
        <div class="denglu">
          登录
          <!-- <div class="qiehuan">
            <a href="javascript:;" @click="sjh = true">
              进行注册
            </a>
          </div> -->
        </div>
        <el-form :model="form" label-position="right" label-width="10px" size="large">
          <el-form-item>
            <!-- <el-input class="input" v-model="form.name"  placeholder="请输入账号" /> -->
            <el-input class="input" v-model="form.name"  placeholder="请输入账号">
             
            </el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-input class="input" v-model="form.name"  placeholder="请输入账号" /> -->
            <el-input class="input" v-model="form.pass" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="pass">
            <el-input class="input" v-model="form.pass" type="password" autocomplete="off" placeholder="请输入密码" />
          </el-form-item> -->
          <el-form-item>
            <el-button class="loginbutton input" type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>

<script  setup>
import {
  reactive,
  ref,
} from 'vue';

// import { Search } from '@element-plus/icons-vue'
import {
  ElMessage,
  ElMessageBox,
} from 'element-plus';
import { useStore } from 'vuex';

import apinew from '../api/apinew';
import router from '../router/index';

const store = useStore()

const sjh = ref(false)
// console.log(store.commit('user/pushuser',{
//   user:1,
//   permission:1
// }));
// store.commit('./user/pushuser'),{
//   user:1,
//   permission:1
// }
// do not use same name with ref
// const goindex =()=>{
//   router.push({ path: '/index' })
// }

const form = reactive({
  name: '',
  pass: '',
  sjh:'',
  yzm:'',
  newname:'',
  newpass:''
})

const onSubmit = () => {

  // 获取登录信息
  let log = {
      "username": form.name,
      "password": form.pass
  }
  // 进行登录
  apinew.login(log).then((req) => {
    console.log(req);
    if (req.data.code == -1) {
      open()
    } else {
      store.commit('user/pushuser', {
        username: form.name,
        time:new Date().getTime()
      })
      router.push({ path: '/index' })
    }
  }).catch(() => {
    console.log(123);
    open()
  })

}

const onregistered = () => {

  // 获取注册信息
  let log = {
      "username": form.newname,
      "password": form.newpass
  }
  // 进行登录
  apinew.add(log).then((req) => {
    console.log(req);
    if (req.data.code == -1) {
      open2()
    } else {
      store.commit('pushuser', {
        user: req.data.user_name,
        permission: req.data.permission
      })
      router.push({ path: '/index' })
    }
  }).catch(() => {
    console.log(123);
    open2()
  })

}
// 登录失败提示
const open = () => {
  ElMessageBox.alert('请输入正确的密码或账号！', '密码或账号错误', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
    callback: () => {
      ElMessage({
        type: 'error',
        message: `账号密码错误`,
      })
    },
  })
}
// 注册失败
const open2 = () => {
  ElMessageBox.alert('该用户名已被注册！', '使用其他用户名！', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
    callback: () => {
      ElMessage({
        type: 'error',
        message: `该用户名已被注册！`,
      })
    },
  })
}
</script>
<style scoped>
.login {
  height: 100%;
  background-color: #304156;
  background-image: url('../assets/bg.png') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.fromcont {
  /* transform: translateY(40%) translateX(-60px); */
  position: relative;
  left: calc(50% - 500px);
  top: 10%;
  /* height: 100%; */
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: right;
  height: 500px;
  border-radius: 20px;
  /* background-image: url('https://pic.vjshi.com/2019-03-12/5ea0483a37bf33322da2788385aa6d58/00002.jpg?x-oss-process=style/watermark') ; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-color: rgb(173, 223, 255); */
  /* opacity: 0.7; */
}

.loginfrom {
  width: 350px;
  background-color: rgb(255, 255, 255);
  height: 300px;
  margin-top: 100px;
  margin-right: 50px;
  border-radius: 20px;
  padding-right: 20px;
  padding-top: 30px;
  /* opacity: 0.7; */

}

.denglu {
  font-size: 26px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.loginbutton {
  width: 100%;
}

.logo {
  width: 300px;
  transform: translateY(20px) translateX(20px);
  /* position: absolut;
  left: 10%; */
}
.gohome{
  position: absolute;
  right: 20px;
  top: 30px;
}
.floottext {
  text-align: center;
  transform: translateY(50px) translateX(60px);
  color: aliceblue;
}
.qiehuan{
  font-size: 12px;
  position: absolute;
  right: 70px;
  top: 140px;
}
</style>